import React, { useCallback, useEffect, useState } from 'react';

import { Box, Checkbox, FormControlLabel } from '@mui/material';

import { useUpdateExperienceValues } from '~/components/Experiences/hooks';

import { ORIGINAL_EXPERIENCE_CONTENT_TAB } from '~/consts/experiences';

import { ExperienceOffer } from '~/services/ExperiencesService';

interface Props {
  experience: ExperienceOffer;
}

const targetAudiences: { value: ExperienceOffer['targetAudiences'][number]; title: string; label: string }[] = [
  { value: 'SOLO', title: 'Just one person', label: 'Solo' },
  { value: 'COUPLE', title: 'Two people', label: 'Couple' },
  { value: 'GROUP', title: 'Three or more people with no kids', label: 'Group' },
  { value: 'FAMILY', title: 'Three or more people with kids', label: 'Family' },
];

function ExperienceCurationTargetAudiences({ experience }: Props) {
  const [selectedTargetAudiences, setSelectedTargetAudiences] = useState<ExperienceOffer['targetAudiences']>([]);

  const { updateValues, contentTab } = useUpdateExperienceValues();

  useEffect(() => {
    setSelectedTargetAudiences(experience.targetAudiences ?? []);
  }, [experience.targetAudiences]);

  const handleTargetAudience = useCallback(
    (event) => {
      const { value, checked } = event.target;

      let updatedTargetAudiences: ExperienceOffer['targetAudiences'] = [];

      if (checked) {
        updatedTargetAudiences = [...selectedTargetAudiences, value];
      } else {
        updatedTargetAudiences = selectedTargetAudiences.filter((targetAudience) => targetAudience !== value);
      }

      setSelectedTargetAudiences(updatedTargetAudiences);
      updateValues({
        targetAudiences: updatedTargetAudiences,
      });
    },
    [selectedTargetAudiences],
  );

  return (
    <Box>
      {targetAudiences.map((item) => (
        <FormControlLabel
          key={item.value}
          title={item.title}
          control={
            <Checkbox
              checked={selectedTargetAudiences.includes(item.value)}
              value={item.value}
              onChange={(e) => {
                handleTargetAudience(e);
              }}
              disabled={contentTab === ORIGINAL_EXPERIENCE_CONTENT_TAB}
            />
          }
          label={item.label}
        />
      ))}
    </Box>
  );
}

export default ExperienceCurationTargetAudiences;
